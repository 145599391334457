import { createApp } from 'vue';
import './scss/main.scss'
import './scss/export.scss'
import axios from "axios";

if (document.getElementById("ico_app")) {
    createApp({
        delimiters: ["[[", "]]"],
        data() {
            return {
                tenderCount: 0,
                ico: null,
                requestRunning: false,
                requestSent: false,
                icoFound: false,
                year1: null,
                count1: null,
                year2: null,
                count2: null,
                year3: null,
                count3: null,
                name: null,
                average: null,
            }
        },
        methods: {
            getCount: function () {
                if (this.ico == null) {
                    return;
                }

                // cancle already running request
                if (this.cancelToken != null) {
                    this.cancelToken.cancel("Operation canceled by the user.");
                }

                // initiate cancel token used to identify, whether ther is a request running already
                this.cancelToken = axios.CancelToken.source();

                // send request to server
                this.requestRunning = true;
                this.requestSent = true;
                axios
                    .get("/rest/tender_count_per_ico/" + this.ico)
                    .then((response) => {
                        this.count1 = response.data.tender_count_1;
                        this.count2 = response.data.tender_count_2;
                        this.count3 = response.data.tender_count_3;
                        this.year1 = response.data.tender_year_1;
                        this.year2 = response.data.tender_year_2;
                        this.year3 = response.data.tender_year_3;
                        this.name = response.data.name;
                        this.average =
                            Math.round(
                                ((this.count1 + this.count2 + this.count3) /
                                    3) *
                                10
                            ) / 10;
                        this.requestRunning = false;
                        this.icoFound =
                            this.count1 > 0 ||
                            this.count2 > 0 ||
                            this.count3 > 0;
                    })
                    .catch((e) => {
                        if (axios.isCancel(e)) {
                            console.log("Request canceled", e.message);
                        } else {
                            console.log(e);
                        }
                        this.requestRunning = false;
                    });
            },
        },
    }).mount("#ico_app")
}

if (document.getElementById("contact_app")) {
    createApp({
        delimiters: ["[[", "]]"],
        data() {
            return {
                email: null,
                requestSent: false,
                requestRunning: false,
                saved: false,
                error: false,
                invalid: false,
            }
        },
        methods: {
            send: function () {
                if (!this.validate()) {
                    return;
                }

                // cancle already running request
                if (this.cancelToken != null) {
                    this.cancelToken.cancel("Operation canceled by the user.");
                }

                // initiate cancel token used to identify, whether ther is a request running already
                this.cancelToken = axios.CancelToken.source();

                // send request to server
                this.requestRunning = true;
                this.requestSent = true;
                axios
                    .get("/rest/save_mail/" + this.email)
                    .then((response) => {
                        if (response.data.result == "ok") {
                            this.requestRunning = false;
                            this.saved = true;
                        } else {
                            this.requestRunning = false;
                            this.error = true;
                        }
                    })
                    .catch((e) => {
                        this.error = true;
                        if (axios.isCancel(e)) {
                            console.log("Request canceled", e.message);
                        } else {
                            console.log(e);
                        }
                        this.requestRunning = false;
                    });
            },
            validate: function () {
                if (!this.validateEmail(this.email)) {
                    this.invalid = true;
                    return false;
                }

                this.invalid = false;
                return true;
            },
            validateEmail: function (email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            },
        },
    }).mount("#contact_app")
}

if (document.getElementById("faq_app")) {
    createApp({
        delimiters: ["[[", "]]"],
        data() { return {} },
    }).mount("#faq_app")
}

if (document.getElementById("video_app")) {
    createApp({
        delimiters: ["[[", "]]"],
        data() {
            return {
                showVideo: true,
                player: null,
                isPlayerPaused: true,
                isPlayerMuted: true,
                isPlayedByUser: false
            }
        },
        mounted: function() {
            this.player = this.$refs.video_player
            this.player.currentTime = 10

            this.isPlayerPaused = this.player.paused
            this.isPlayerMuted = this.player.muted
        },
        methods: {
            playPauseVideo: function() {
                if (!this.isPlayedByUser) {
                    this.player.muted = false
                    this.player.loop = false
                    this.player.controls = true
                    this.player.volume = 1
                    this.player.currentTime = 0
                    this.player.play()

                    this.isPlayedByUser = true
                } else if (this.isPlayerPaused) {
                    this.player.play()
                } else {
                    this.player.pause()
                }
            },
            replayVideo: function() {
                this.player.currentTime = 0
                this.player.play()
            },
            handlePlayerInerState: function(evt) {
                this.isPlayerPaused = evt.target.paused
                this.isPlayerMuted = evt.target.muted
            }
        },
    }).mount("#video_app")
}

if (document.getElementById("video_app2")) {
    createApp({
        delimiters: ["[[", "]]"],
        data() {
            return {
                showVideo2: false,
                showVideo3: false,
            }
        },
        methods: {
            showVideoPlayer2: function () {
                this.showVideo2 = true;
                var player = document.getElementById("tenderman_video2");
                player.play();
            },
            hideVideoPlayer2: function () {
                this.showVideo2 = false;
                var player = document.getElementById("tenderman_video2");
                player.pause();
            },
            pauseVideoPlayer2: function () {
                var player = document.getElementById("tenderman_video2");
                if (player.paused) {
                    player.play();
                } else {
                    player.pause();
                }
            },
            showVideoPlayer3: function () {
                this.showVideo3 = true;
                var player = document.getElementById("tenderman_video3");
                player.play();
            },
            hideVideoPlayer3: function () {
                this.showVideo3 = false;
                var player = document.getElementById("tenderman_video3");
                player.pause();
            },
            pauseVideoPlayer3: function () {
                var player = document.getElementById("tenderman_video3");
                if (player.paused) {
                    player.play();
                } else {
                    player.pause();
                }
            },
        },
    }).mount("#video_app2")
}


if (document.getElementById("contact_form_app")) {
    createApp({
        delimiters: ["[[", "]]"],
        data: function() {
            return {
                sent: false,
                busy: false,
                name: null,
                phone: null,
                email: null
            }
        },
        mounted: function() {
            this.$refs.email_input.oninvalid = this.invalidEmailMsg
        },
        methods: {
            invalidEmailMsg: function(evt) {
                input = evt.target

                if (input.value === '') {
                    input.setCustomValidity('E-mail je povinný')
                } else if (input.validity.typeMismatch) {
                    input.setCustomValidity('Řetězec musí být e-mailová adresa')
                } else {
                    input.setCustomValidity('')
                }

                return true
            },
            send: function() {
                if (this.sent) {
                    console.warn("Contact form aleready sent")
                    return
                }

                let data = {
                        contact_name: this.name,
                        contact_phone: this.phone,
                        contact_email: this.email
                    },
                    csrfToken = document.getElementsByName('csrfmiddlewaretoken')[0].value,
                    options = {
                        headers: {
                            'X-CSRFToken': csrfToken
                        }
                    }

                this.busy = true
                this.sent = false

                axios
                    .post("/rest/contact_form/", data, options)
                    .then(response => this.sent = true)
                    .catch(e => console.error(e))
                    .finally(() => this.busy = false)
            }
        }
    }).mount("#contact_form_app")
}